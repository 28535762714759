import request from '@/utils/request'


export function getAssetsDataFromFirebase() {
    return request({
        url: '/dynamicassets/getconfigdata',
        method: 'get'
    })
}

export function activateAssetsDataFromFirebase(rowID, user, active) {
    return request({
        url: '/dynamicassets/activatedata/' + rowID + "/" + user + "/" + active,
        method: 'get'
    })
}

export function deleteAssetsFromFirebase(rowID, user) {
    return request({
        url: '/dynamicassets/deletedata/' + rowID + '/' + user,
        method: 'get'
    })
}

export function uploadAssetsToFirebase( assetGroup,
                                        assetDescription,
                                        eventID,
                                        downloadType,
                                        from_version,
                                        to_version,
                                        isActivated,
                                        user,
                                        fileNameList,
                                        fileSizeList) {
    return request({
        url: '/dynamicassets/upload/',
        method: 'post',
        data: {
            assetGroup: assetGroup,
            assetDescription: assetDescription,
            eventID: eventID,
            downloadType: downloadType,
            from_version: from_version,
            to_version: to_version,
            activated: isActivated,
            user: user,
            fileNameList: fileNameList,
            fileSizeList: fileSizeList
        }
    })
}

export function quickUploadAssetsToFirebase( configData, user) {
    return request({
        url: '/dynamicassets/quickupload/',
        method: 'post',
        data: {
            configData: configData,
            user: user
        }
    })
}

export function editAssetsToFirebase(  
    row_id,
    assetGroup,
    assetDescription,
    eventID,
    downloadType,
    from_version,
    to_version,
    isActivated,
    user) 
{

return request({
                url: '/dynamicassets/updatedata/',
                method: 'post',
                data: {
                    row_id: row_id,
                    assetGroup: assetGroup,
                    assetDescription: assetDescription,
                    eventID: eventID,
                    downloadType: downloadType,
                    from_version: from_version,
                    to_version: to_version,
                    activated: isActivated,
                    user: user
                    }
                })
}

export function dynamic_assets_holdingSystemToFirebase(  
    is_holding,
    user) 
{

return request({
                url: '/dynamicassets/holdingsystem/',
                method: 'post',
                data: {
                    is_holding: is_holding,
                    user: user
                    }
                })
}

export function copy(o) {
    if (o === null) return null;
    let output, v, key
    output = Array.isArray(o) ? [] : {}
    
    for (key in o) {
      v = o[key]
      if(v) {
        output[key] = (typeof v === "object") ? copy(v) : v
      } else {
        output[key] = v
      }
    }
    return output;
}